// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
var submitForm = function (event) {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    var email = document.getElementById('email').value;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    var message = document.getElementById('message').value;
    var body = { email: email, message: message };
    fetch('https://formspree.io/f/mzbkzdlw', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(function (response) { return response.json(); })
        .then(function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('email').value = '';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('message').value = '';
        // eslint-disable-next-line no-alert
        alert('Thank you for your message!');
    })
        .catch(function (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
    });
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
var addFormEventListener = function () {
    var form = document.getElementById('form');
    form.addEventListener('submit', submitForm);
};
export default addFormEventListener;
