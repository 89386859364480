import * as THREE from 'three'

const getRender = (sizes, canvas) => {
  /**
     * Renderer
     */
  const renderer = new THREE.WebGLRenderer({
    canvas,
    alpha: true,
  })
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  return renderer
}

export default getRender
