import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const getControls = (camera, canvas) => {
  // Controls
  const controls = new OrbitControls(camera, canvas)
  controls.enableDamping = true
  controls.enableZoom = false
  return controls
}

export default getControls
