import '../../../../style.css'

import * as THREE from 'three'
import generateGalaxy from './generateGalaxy.js'
import addTweakGui from './helperFunctions/addTweakGui.js'
import addCamera from './helperFunctions/addCamera.js'
import addResizeEventListener from './helperFunctions/addResizeEventListener.js'
import getSizes from './helperFunctions/getSizes.js'
import getControls from './helperFunctions/getControls.js'
import getRender from './helperFunctions/getRender.js'
import getCanvas from './helperFunctions/getCanvas.js'
import getScene from './helperFunctions/getScene.js'
import getGalaxyGeometry from './helperFunctions/getGalaxyGeometry.js'

/**
 * Galaxy
 */
const parameters = {
  count: 40000,
  size: 0.01,
  radius: 6,
  branches: 6,
  spin: 1,
  randomness: 0.27,
  randomnessPower: 3,
  insideColor: '#ff6030',
  outsideColor: '#1b3984',
}

const canvas = getCanvas()
const scene = getScene()

/**
 * Textures
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const loadTexture = (colorTextureUrl, heightTextureUrl, normalTextureUrl, ambientOcclusionTextureUrl, roughnessTextureUrl, metalnessTextureUrl, alphaTextureUrl) => {
  const loadingManager = new THREE.LoadingManager()

  loadingManager.onStart = () => {
    console.log('loading start...')
  }
  loadingManager.onLoaded = () => {
    console.log('loading finished...')
  }
  loadingManager.onProgress = () => {
    console.log('loading progressing...')
  }
  loadingManager.onError = () => {
    console.log('loading error...')
  }

  const textureLoader = new THREE.TextureLoader(loadingManager)
  const colorTexture = textureLoader.load(colorTextureUrl)
  const alphaTexture = textureLoader.load(alphaTextureUrl)
  const heightTexture = textureLoader.load(heightTextureUrl)
  const normalTexture = textureLoader.load(normalTextureUrl)
  const ambientOcclusionTexture = textureLoader.load(ambientOcclusionTextureUrl)
  const metalnessTexture = textureLoader.load(metalnessTextureUrl)
  const roughnessTexture = textureLoader.load(roughnessTextureUrl)

  colorTexture.minFilter = THREE.NearestFilter
  return {
    colorTexture, normalTexture, alphaTexture, heightTexture, ambientOcclusionTexture, metalnessTexture, roughnessTexture,
  }
}

/**
 * Light
 */

const lightHemisphere = new THREE.HemisphereLight(0xffffbb, 0x080820, 1)
scene.add(lightHemisphere)

const texturePathSun = './textures/sun/2k_sun.jpeg'
const geometrySun = new THREE.SphereBufferGeometry(0.3, 64, 64)
const sphereSun = loadTexture(
  texturePathSun,
)
const materialSun = new THREE.MeshStandardMaterial({
  map: sphereSun.colorTexture,
})

const meshSun = new THREE.Mesh(geometrySun, materialSun)
meshSun.position.x = -2
meshSun.position.z = 2
scene.add(meshSun)

const texturePathEarth = './textures/earth/2k_earth_daymap.jpeg'
const geometryEarth = new THREE.SphereBufferGeometry(0.15, 64, 64)
const sphereEarth = loadTexture(
  texturePathEarth,
)
const materialEarth = new THREE.MeshStandardMaterial({
  map: sphereEarth.colorTexture,
})

const meshEarth = new THREE.Mesh(geometryEarth, materialEarth)
meshEarth.position.x = -3
meshEarth.position.z = 3
scene.add(meshEarth)

const geometryBlackHole = new THREE.SphereBufferGeometry(0.82, 64, 64)
const materialBlackHole = new THREE.MeshBasicMaterial({
  color: 'black',
})

const meshBlackHole = new THREE.Mesh(geometryBlackHole, materialBlackHole)
scene.add(meshBlackHole)

const galaxyGeometry = getGalaxyGeometry()
generateGalaxy(parameters, scene, galaxyGeometry)
const sizes = getSizes()
const camera = addCamera(scene, sizes)
const controls = getControls(camera, canvas)
const renderer = getRender(sizes, canvas)
addResizeEventListener(sizes, camera, renderer)

/**
 * Animate
 */
const clock = new THREE.Clock()

const isTweaksVisible = false

if (isTweaksVisible) {
  addTweakGui(parameters, () => generateGalaxy(parameters, scene, galaxyGeometry))
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const cos = (theta) => Math.cos(theta)
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const sin = (theta) => Math.sin(theta)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const tick = () => {
  const deltaTime = clock.getDelta()

  // not optimal solution, but works, should use a custom shader in the future to optimize
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < parameters.count; i++) {
    const i3 = i * 3
    const xIndex = i3 + 0
    const yIndex = i3 + 1
    const zIndex = i3 + 2

    const xPosition = galaxyGeometry.attributes.position.array[xIndex]
    const yPosition = galaxyGeometry.attributes.position.array[yIndex]
    const zPosition = galaxyGeometry.attributes.position.array[zIndex]

    const theta = 0.1 * deltaTime

    galaxyGeometry.attributes.position.array[xIndex] = (xPosition * cos(theta)) + (zPosition * sin(theta))
    galaxyGeometry.attributes.position.array[yIndex] = yPosition
    galaxyGeometry.attributes.position.array[zIndex] = (-xPosition * sin(theta)) + (zPosition * cos(theta))
  }
  galaxyGeometry.attributes.position.needsUpdate = true

  if (meshSun.position.x <= 0 && meshSun.position.z >= 0) {
    meshSun.position.x += deltaTime
    meshSun.position.z -= deltaTime
  }

  if (meshEarth.position.x <= 0 && meshEarth.position.z >= 0) {
    meshEarth.position.x += 0.75 * deltaTime
    meshEarth.position.z -= 0.75 * deltaTime
  }

  meshSun.rotation.z += deltaTime

  meshEarth.rotation.z += deltaTime

  // Update controls
  controls.update()

  // Render
  renderer.render(scene, camera)

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)
}

export default tick
