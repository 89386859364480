import * as THREE from 'three'

const numberOfDimensions = 3

// This are passed into the fn to ensure they are cleaned up correctly
let geometry = null
let material = null
let points = null

const generateGalaxy = (parameters, scene, galaxyGeometry) => {
  // Destroy old galaxy
  if (points !== null) {
    geometry.dispose()
    material.dispose()
    scene.remove(points)
  }

  /**
     * Generate Galaxy
     */
  geometry = galaxyGeometry
  const position = new Float32Array(parameters.count * numberOfDimensions)
  const colors = new Float32Array(parameters.count * numberOfDimensions)

  const colorInside = new THREE.Color(parameters.insideColor)
  const colorOutside = new THREE.Color(parameters.outsideColor)

  for (let i = 0; i < parameters.count; i++) {
    const i3 = i * numberOfDimensions
    const radius = parameters.radius * Math.random()
    const spinAngle = radius * parameters.spin
    const theta = Math.PI * 2 * (i % parameters.branches) / parameters.branches

    const randomX = Math.random() ** parameters.randomnessPower * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius
    const randomY = Math.random() ** parameters.randomnessPower * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius
    const randomZ = Math.random() ** parameters.randomnessPower * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius

    const xPosition = i3 + 0
    const yPosition = i3 + 1
    const zPosition = i3 + 2

    const redColor = i3 + 0
    const greenColor = i3 + 1
    const blueColor = i3 + 2

    const mixedColor = colorInside.clone()
    mixedColor.lerp(colorOutside, radius / parameters.radius)

    colors[redColor] = mixedColor.r
    colors[greenColor] = mixedColor.g
    colors[blueColor] = mixedColor.b

    position[xPosition] = (randomX + radius) * Math.cos(theta + spinAngle)
    position[yPosition] = randomY
    position[zPosition] = (randomZ + radius) * Math.sin(theta + spinAngle)

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(position, numberOfDimensions))
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, numberOfDimensions))
  }

  /**
     * Generate Galaxy Material
     */
  material = new THREE.PointsMaterial({
    color: parameters.color,
    size: parameters.size,
    sizeAttenuation: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    vertexColors: true,
  })

  /**
     * Generate Galaxy Points
     */
  points = new THREE.Points(geometry, material)
  scene.add(points)
}

export default generateGalaxy
