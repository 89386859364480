import * as THREE from 'three'

const addCamera = (scene, sizes) => {
  /**
     * Camera
     */
  // Base camera
  const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
  camera.position.x = 3
  camera.position.y = 1.5
  camera.position.z = 1.5
  scene.add(camera)
  return camera
}

export default addCamera
